<template>
  <div id="app">
    <div class="app-total">
      <total ref="Totals" />
    </div>
  </div>
</template>

<script>
import total from '@/components/total.vue';
import { getSessionId, getAllCarId, transportTotal } from '@/api/index.js'
import axios from 'axios';
export default {
  name: 'App',
  components: {
    total
  },
  data() {
    return {
      queryparams: {
        // user: '运盛监管05',
        // password_md5: 'a806f5026dabadc5cff19211d9f4afa2'
        user: '',
        password_md5:''
      },
      sessionId: '',
      AllcarId: [],
      timer: null
    }
  },
  beforeMount() {
    // 获取浏览器导航值
    this.getSearchValue();
  },
  methods: {
    getSearchValue() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const p1 = params.get('username');
      const p2 = params.get('password_md5');
      this.queryparams.user = p1;
      this.queryparams.password_md5 = p2;
      // console.log("值1", this.queryparams);
      this.getId();
    },
    // 获取sessionId
    getId() {
      // getSessionId(this.queryparams)
      axios({
        url: 'https://tmswx.xunjian.xin/system/worksheet/sessionId',
        method: 'get',
        params: this.queryparams
      })
      .then(({ data, status }) => {
        // console.log("res", res);
        if (!!data && status == 200) {
          this.sessionId = data.data;
          this.$nextTick(() => {
            !!this.$refs.Totals && this.$refs.Totals.init(data.data, this.queryparams)
          })
        }
      })
    },
    getCarIds() {
      getAllCarId(this.queryparams)
      .then((res) => {
        console.log("所有车辆id", res);
        if (!!res && res.code == 200) {
          this.AllcarId = res.data;
        }
      })
    },
    getMapPoint() {
      const request_session = getSessionId(this.queryparams);
      const request_carids = getAllCarId(this.queryparams);
      Promise.all([request_session, request_carids])
      .then(([response1, response2]) => {
        console.log("111",response1);
        console.log("222", response2);
        if ((!!response1 && response1.code == 200) && (!!response2 && response2.code == 200)) {
          let sessionId = response1.data;
          let carids = response2.data.join(',');
          // https://tms.xunjian.xin/gps-web/api/get_gps_r.jsp?sessionId=s0ERmkWNhBqrnHmmbeuZfZ3XoQmZzI2TzhWdxpmUoI1NxUTQzQHd3FmXhlXMmI5mnHXk_iuco8FafVkYFV2VkBXb18lTfZDNOJTSwgDeY&teamId=&carIds=61, 63, 64,65,66,67,68,330,298,299,332&simple=false
          this.getdetails(sessionId, carids)

          this.startInterval(sessionId, carids);
        }
      })
    },
    getdetails(sessionId, carids) {
      axios({
        url: '/apis/gps-web/api/get_gps_r.jsp',
        method: 'GET',
        params: {
          sessionId: sessionId,
          carIds: carids
        }
      })
      .then((res) => {
        console.log("res三一", res);
      })
    },
    startInterval(sessionId, carids) {
      this.timer = setInterval(() => {
        this.getdetails(sessionId, carids);
      }, 30000); // 
    },
    gettotal() {
      transportTotal(this.queryparams)
      .then((res) => {
        console.log("线路统计", res);
      })
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html,body,#app {
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #EBECF1;
}

#app {
  box-sizing: border-box;
  padding: 20px;
}

.app-total {
  position: relative;
  width: 100%;
  /* height: 100%; */
  box-sizing: border-box;
  padding: 30px;
  background: #F8F9FB;
  border-radius: 20px;
}
</style>
