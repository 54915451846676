import Vue from 'vue'
import App from './App.vue'

// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

// 监听元素大小变化(为echarts服务)
import ElementResizeDetectorMaker from "element-resize-detector";
Vue.prototype.$erd = ElementResizeDetectorMaker();

// 引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);

// 高德地图
// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);

// 初始化vue-amap(高德地图组件)
// VueAMap.initAMapApiLoader({
//   // 高德的key
//   key: '10a991028ad3f967b6ec72780030bf0c',
//   // 插件集合
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor',
//     'AMap.CircleEditor', 'AMap.MarkerClusterer', 'AMap.MouseTool', 'AMap.ContextMenu', 'AMap.Marker', 'AMp.InfoWindow', 'AMap.Geocoder',
//     'AMap.Driving','AMap.DrivingRender','AMap.MoveAnimation', 'AMap.Label', 'AMap.InfoWindow'
//   ],
//   // 高德 sdk版本
//   v: '1.4.4'
// })

Vue.config.productionTip = false

new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
