import request from '@/utils/request'

// 获取sessionId
export function getSessionId(query) {
  return request({
    url: '/system/worksheet/sessionId',
    method: 'get',
    params: query
  })
}

// 获取运输中的所有车辆id
export function getAllCarId(query) {
  return request({
    url: '/system/worksheet/carIds',
    method: 'get',
    params: query
  })
}

// 运输线路统计
export function transportTotal(query) {
  return request({
    url: '/system/worksheet/transportStatistics',
    method: 'get',
    params: query
  })
} 

// 获取报警统计
export function getAlarmTotal(query) {
  return request({
    url: '/system/worksheet/alarmStatistics',
    params: 'get',
    params: query
  })
}
