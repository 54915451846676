import Vue from "vue";
// 高德离线地图
import VueAMap from "vue-amap";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  // 高德key
  key: "27fa9c24de135603193637230e5160e7", //  申请好的Web端开发者Key
  // 插件集合 （插件按需引入）
  plugins: ["AMap.AutoComplete", "AMap.PlaceSearch", "AMap.Driving", "AMap.DragRoute", "AMap.Map", "AMap.LngLat", "AMap.Size"],
  v: "2.0", // 版本
  uiVersion: "1.0.11", // ui版本号
});
